<template>
  <div
    class="mt-7 mb-4 flex h-px w-full items-center justify-center border-b border-slate-300 font-semibold text-slate-500"
  >
    <span class="bg-white px-2.5">
      {{ t(`labels.${label}`) }}
    </span>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
