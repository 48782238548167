<template>
  <div class="flex">
    <div
      class="flex h-full w-full items-center justify-center md:block md:w-1/2 lg:w-2/5"
    >
      <div
        class="mx-4 flex w-full items-center justify-center rounded-md bg-white py-8 shadow-md sm:mx-32 md:mx-0 md:h-full md:rounded-none"
      >
        <div class="w-full max-w-md px-4 sm:w-2/3">
          <div class="flex flex-col gap-x-8 py-4 xl:flex-row xl:items-center">
            <img
              class="h-10 object-contain object-left"
              src="/assets/logo-dark.png"
            />
            <div class="hidden h-8 border border-slate-500 xl:block" />
            <div class="text-xl uppercase">{{ t("labels.manager") }}</div>
          </div>
          <h1>{{ t("headings.sign_in") }}</h1>
          <custom-form
            :formElements="formElements"
            formStyle="block"
            @submitForm="signIn"
          />
          <!--<form-divider label="alternative_methods" />
          <div class="grid grid-cols-2 gap-3 py-3">
            <form-button
              v-for="method in alternativeMethods"
              :key="method.label"
              :command="method.command"
              :id="method.label"
              :label="method.label"
              textVariant="light"
              type="button"
              variant="indigo"
            />
          </div>-->
          <div class="flex justify-center p-4">
            <router-link class="hover:underline" to="forgot">{{
              t("links.forgot")
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow" />
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import CustomForm from "../components/form/CustomForm.vue";
import FormButton from "../components/form/FormButton.vue";
import FormDivider from "../components/form/FormDivider.vue";
export default {
  components: { CustomForm, FormButton, FormDivider },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const formElements = ref([
      {
        class: "col-span-12",
        element: "input",
        id: "email",
        label: "email",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "username",
      },
      {
        class: "col-span-12",
        element: "input",
        id: "password",
        label: "password",
        rules: ["isRequired"],
        type: "password",
        value: "",
        variable: "password",
      },
      {
        class: "col-span-12",
        element: "button",
        id: "submit-button",
        label: "sign_in",
        textVariant: "light",
        type: "submit",
        variant: "indigo",
      },
    ]);

    const alternativeMethods = ref([
      {
        command: () => console.log("Google"),
        label: "google",
      },
      {
        command: () => console.log("Microsoft"),
        label: "microsoft",
      },
    ]);

    return {
      alternativeMethods,
      formElements,
      signIn: (data) => store.dispatch("authentication/signIn", data),
      t,
    };
  },
};
</script>
